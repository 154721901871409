<template>
  <div class="apple">
    <h1>Test 1</h1>
    <h2>orderId: {{ orderId }}</h2>
    <div id="apple" />
    <div class="apple-pay-button" />
  </div>
</template>

<script>
const amount = 1
const merchantIdentifier = 'merchant.com.beyounger.sandbox'
const currencyCode = 'USD'
const countryCode = 'CN'
const orderId = '23090804345819749'
console.log(orderId)
const baseUrl = 'https://api-sandbox.beyounger.com'
const BACKEND_URL_VALIDATE_SESSION = `${baseUrl}/v1/saas/applepay/validateSession`
const BACKEND_URL_VALIDATE_PAY = `${baseUrl}/v1/channel/payment`
const publicKey = 'pk_sbox_m3dhzcsbmlxmnqypu6zii5j5nec'
const tokenUrl = 'https://api.sandbox.checkout.com/tokens'
const applePayUrl = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'

export default {
  data() {
    return {
      orderId
    }
  },
  mounted() {
    let finalID = this.orderId
    console.log(this.$route)
    if (this.$route?.query?.id) {
      finalID = this.$route?.query?.id
      this.orderId = this.$route?.query?.id
    }
    console.log(finalID)
    const { ApplePaySession } = window
    function onApplePayLoaded() {
      const applePayButton = document.querySelector('.apple-pay-button')
      const request = {
        countryCode,
        currencyCode,
        supportedNetworks: ['amex', 'visa', 'masterCard', 'discover'],
        merchantCapabilities: ['supports3DS'],
        // requiredShippingContactFields: ['postalAddress', 'name', 'phone'],
        // requiredBillingContactFields: ['postalAddress', 'name'],
        total: {
          label: 'Apple Pay Web Example',
          amount
        }
      }
      applePayButton.addEventListener('click', () => {
        // e.preventDefault();
        const applePaySession = new ApplePaySession(1, request)
        applePaySession.onvalidatemerchant = (event) => {
          const validUrl = event.validationURL
          console.log(validUrl)

          const validateTheSession = (callback) => {
            fetch(`${BACKEND_URL_VALIDATE_SESSION}?appleUrl=${validUrl}`, {
              method: 'GET', // *GET, POST, PUT, DELETE, etc.
              headers: {
                'Access-Control-Allow-Origin': '*'
                // Authorization: `Bearer ${publicKey}`,
              }
              // body: JSON.stringify({
              //   appleUrl: validUrl,
              // }), // body data type must match 'Content-Type' header
            })
              .then(response => response.json())
              .then((result) => {
                console.log('Success:', result)
                callback(result)
              })
              .catch((error) => {
                console.error('Error:', error)
              })
          }

          validateTheSession((merchantSession) => {
            console.log(merchantSession)
            const a = applePaySession.completeMerchantValidation(merchantSession)
            console.log('a ', a)
            if (a && a.then) {
              a.then((res) => {
                console.log('res', res)
              })
                .catch((err) => {
                  console.log('err', err)
                })
            } else {
              console.log('!a.then')
            }
          })
        }

        applePaySession.onpaymentauthorized = (event) => {
          console.log('onpaymentauthorized')
          console.log(event)

          const applePayToken = event.payment.token
          console.log(applePayToken)
          const pay = (applePayTokenP, callback) => {
            const token_data = applePayTokenP.paymentData

            console.log(token_data, callback)

            const params = {
              type: 'applepay',
              token_data
            }
            fetch(tokenUrl, {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${publicKey}`
              },
              body: JSON.stringify(params) // body data type must match 'Content-Type' header
            })
              .then(response => response.json())
              .then((result) => {
                console.log('Success:', result)
                // submitResult(result.token , apple);
                // submitResult(result.token);
                console.log(result.token)
                console.log(finalID)
                fetch(BACKEND_URL_VALIDATE_PAY, {
                  method: 'POST', // *GET, POST, PUT, DELETE, etc.
                  headers: {
                    'Content-Type': 'application/json'
                    // 'Access-Control-Allow-Origin': '*',
                    // Authorization: `Bearer ${publicKey}`,
                  },
                  body: JSON.stringify({
                    tokenization: result.token,
                    id: finalID
                  }) // body data type must match 'Content-Type' header
                })
                  .then(response => response.json())
                  .then((result2) => {
                    console.log('Success:', result2)
                    // ApplePaySession.completePayment();
                    // callback()
                    applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS)
                  })
                  .catch((error) => {
                    console.error('Error:', error)
                    applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                  })
              })
              .catch((error) => {
                applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                console.error('Error:', error)
              })
          }

          pay(applePayToken)
        }

        // applePaySession.onpaymentmethodselected = (event) => {
        //   console.log('onpaymentmethodselected');
        //   console.log(event);
        //   // event.updateWith({});
        //   applePaySession.completePaymentMethodSelection();
        //   event.updateWith({});
        // };

        // applePaySession.onshippingcontactselected = (event) => {
        //   console.log('onshippingcontactselected');
        //   console.log(event);
        //   event.updateWith({});
        //   // request = applePayShippingContactSelected(applePaySession, request, event);
        // };

        // applePaySession.onshippingmethodselected = (event) => {
        //   console.log('onshippingmethodselected');
        //   console.log(event);
        //   // event.updateWith({});
        // };

        applePaySession.oncancel = (event) => {
          console.log('oncancel')
          console.log(event)
        }
        applePaySession.begin()
      })
      if (ApplePaySession) {
        const canMakePayments = ApplePaySession.canMakePayments(merchantIdentifier)
        console.log('canMakePayments', canMakePayments)
        if (canMakePayments) {
          document.getElementById('apple').style.display = 'block'
          // document.getElementById('apple-placeholder').style.display = 'none';
        } else {
          document.getElementById('apple').style.display = 'none'
          // document.getElementById('apple-placeholder').style.display = 'none';
        }
      }
    }

    function initApplePayJs() {
      console.log('init apple js')
      const script = document.createElement('script')
      const fn = onApplePayLoaded
      script.src = applePayUrl
      script.type = 'text/javascript'
      script.crossOrigin = true
      script.async = true

      // IE
      if (script.readyState) {
        script.onreadystatechange = function() {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null
            fn()
          }
        }
      } else {
        // 其他浏览器
        script.onload = function() {
          fn()
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    }

    console.log('applePayUrl', applePayUrl)
    if (window.ApplePaySession) {
      // document.getElementById('apple-placeholder').style.display = 'block';
      document.getElementById('apple').style.display = 'none'
      initApplePayJs()
    } else {
      // document.getElementById('apple-placeholder').style.display = 'none';
      document.getElementById('apple').style.display = 'none'
    }
  }
}

</script>

<style>
.apple-pay-button {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  background-color: rgb(0, 69, 255, 0.75) !important;
  -webkit-appearance: -apple-pay-button;
}

apple-pay-button {
  --apple-pay-button-width: 150px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}
</style>
